import { computed, toRefs, ref, watch } from 'vue';

import { isNullish } from '@ui/utils/nullish';

function toText(val: any, extractor: (s: string) => string) {
  const text = Array.isArray(val) ? val.map(extractor).join(', ') : extractor(val);
  return text || '';
}

interface ValueProps {
  multiple: boolean;
  max: number;
  valueProp: string;
  labelProp: string;
  valueAsObject: boolean;
}

export default function useValue(props: ValueProps, emit: ReturnType<typeof defineEmits>) {
  const { multiple, max, valueProp, labelProp, valueAsObject } = toRefs(props);

  // internalValue
  const iv = ref<[] | object>(makeInternal());

  function makeInternal(val?: any) {
    if (isNullish(val)) {
      return multiple.value ? [] : {};
    }

    return val;
  }

  watch(multiple, () => {
    setInternal(undefined);
  });

  function setInternal(val: any) {
    iv.value = makeInternal(val);
  }

  function getValue(v: any) {
    return v[valueProp.value];
  }

  function getLabel(v: any) {
    return v[labelProp.value];
  }

  function makeExternalOne(val: any) {
    if (valueAsObject.value || isNullish(val) || typeof val !== 'object') return val;

    return getValue(val);
  }

  function makeExternal(val: any): any {
    if (Array.isArray(val)) return val.map(makeExternalOne);

    return makeExternalOne(val);
  }

  function update(val: any) {
    setInternal(val);

    const externalVal = makeExternal(val);

    emit('input:value', val); // Объект
    emit('update:modelValue', externalVal); // Необходимый формат в зависимости от valueAsObject
  }

  function clear() {
    emit('clear');
    update(multiple.value ? [] : null);
  }

  const hasSelected = computed(() => {
    return multiple.value ? Boolean(Array.isArray(iv.value) && iv.value.length) : !isNullish(getValue(iv.value));
  });

  function isMax() {
    if (max.value === -1 || (!hasSelected.value && max.value > 0)) {
      return false;
    }

    return Array.isArray(iv.value) && iv.value.length >= max.value;
  }

  const valueText = computed(() => toText(iv.value, getValue));
  const labelText = computed(() => toText(iv.value, getLabel));

  return {
    iv,
    setInternal,
    makeExternal,
    makeExternalOne,
    clear,
    hasSelected,
    isMax,
    valueText,
    labelText,
    getValue,
    update,
  };
}
