import { toRefs, watch } from 'vue';
import type { Ref } from 'vue';

import { isArraysEqual, toArray } from '@ui/utils/array';
import { isNullish } from '@ui/utils/nullish';
import { useOptionsResolver } from './useOptionsResolver';
import type { SearchFilter } from './useSearch';

export interface UiSelectorOption {
  disabled: boolean;
}

function isDisabled(option: UiSelectorOption) {
  return option.disabled;
}

interface useOptionsDep {
  setInternal: (v: any) => void;
  getValue: (v: any) => string;
  makeExternalOne: (v: any) => any;
  makeExternal: (v: any) => any;
  isMax: () => boolean;
  iv: Ref<any>;
  search: Ref<string>;
  searchFilter: SearchFilter;
  clear: () => void;
  clearSearch: () => void;
  update: any;
  show: Ref<boolean>;
}

export default function useOptions(props: any, emit: ReturnType<typeof defineEmits>, dep: useOptionsDep) {
  const { modelValue: ev, multiple } = toRefs(props);

  const { iv, search, clear, clearSearch, searchFilter, update, makeExternal, makeExternalOne, getValue, isMax, setInternal, show } = dep;

  const { eo, loading, findOption, filteredOptions, searchOptionById, useLoading } = useOptionsResolver(props, { search, searchFilter, getValue });

  function select(option: any) {
    if (typeof option !== 'object') {
      option = findOption(option);
    }

    if (!multiple.value) {
      update(option);
    } else {
      update(iv.value.concat(option));
    }

    clearSearch();
    emit('select', makeExternalOne(option), option);
  }

  function selectAll() {
    update(iv.value.concat(filteredOptions.value.filter((el) => !iv.value.includes(el))));
  }

  function deselect(option: any) {
    if (typeof option !== 'object') {
      option = findOption(option);
    }

    if (!multiple.value) {
      clear();
    } else {
      update(
        Array.isArray(option)
          ? iv.value.filter((v: any) => !option.map(getValue).includes(getValue(v)))
          : iv.value.filter((v: any) => getValue(v) !== getValue(option)),
      );
    }

    emit('deselect', makeExternalOne(option), option);
  }

  function isSelected(option: any) {
    if (!multiple.value) {
      return !isNullish(iv.value) && getValue(iv.value) === getValue(option);
    } else {
      return !isNullish(iv.value) && Array.isArray(iv.value) && iv.value.map(getValue).includes(getValue(option));
    }
  }

  function handleOptionClick(option: any) {
    if (isDisabled(option)) {
      return;
    }

    if (!multiple.value) {
      if (isSelected(option)) {
        return;
      }

      select(option);
    } else {
      if (isSelected(option)) {
        deselect(option);
        return;
      }

      if (isMax()) return;

      if (findOption(getValue(option)) === undefined) {
        return;
      }

      select(option);
    }

    if (!multiple.value) {
      show.value = false;
    }
  }

  async function makeInternalOne(val: any) {
    if (typeof val === 'object') return val;

    return useLoading(() => searchOptionById(val));
  }

  async function makeInternalArray(val: any) {
    return useLoading(() =>
      Promise.all(
        toArray(val).map((v) => {
          if (typeof v === 'object') return v;

          const optionInValue = findOption(v, iv.value);
          return optionInValue || searchOptionById(v);
        }),
      ),
    );
  }

  watch(
    ev,
    async (newValue) => {
      if (isNullish(newValue)) {
        setInternal(newValue);
        emit('input:value', iv.value); // Объект
        return;
      }

      if (multiple.value) {
        if (!isArraysEqual(makeExternal(newValue), iv.value.map(getValue))) {
          iv.value = await makeInternalArray(newValue);
        }
      } else {
        if (makeExternalOne(newValue) !== getValue(iv.value)) {
          iv.value = await makeInternalOne(newValue);
        }
      }
      emit('input:value', iv.value); // Объект
    },
    { deep: true, immediate: true },
  );

  return {
    eo,
    filteredOptions,
    loading,
    select,
    selectAll,
    deselect,
    isSelected,
    handleOptionClick,
  };
}
