import type { Placement } from '@floating-ui/vue';
import { Placements } from '@ui/utils/placements';

export function useDropdown() {
  const show = ref(false);

  function open() {
    if (!show.value) show.value = true;
  }

  function close() {
    if (show.value) show.value = false;
  }

  function toggle() {
    show.value = !show.value;
  }

  const placement = ref<Placement>(Placements.Bottom);

  const openStatus = computed(() => show.value ? placement.value : false);

  return {
    show,
    placement,
    openStatus,
    open,
    close,
    toggle,
  };
}