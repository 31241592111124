<script setup lang="ts">
import Input from '../Input.vue';
import { type BackgroundType, type Sizes, Styles } from '@ui/styles';
import Chevron from '../../chevron/Chevron.vue';
import List from '../../list/List.vue';
import ListItem from '../../list/ListItem.vue';
import Dropdown from '../../dropdown/Dropdown.vue';
import { useElementSize } from '@vueuse/core';
import useValue from './composables/useValue';
import useSearch from './composables/useSearch';
import useOptions from './composables/useOptions';
import type { OptionFunction } from './composables/useOptionsResolver';
import type { Placement } from '@floating-ui/vue';
import { useDropdown } from '@ui/components/dropdown/useDropdown';

type OptionType = string | number | object;

const props = withDefaults(defineProps<{
  modelValue: string | number | string[] | number[] | object;
  size?: Sizes;
  label?: string;
  placeholder?: string;
  tooltip?: string;
  message?: string;
  error?: boolean;
  clean?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  background?: BackgroundType;
  max?: number;
  multiple?: boolean;
  labelProp?: string;
  valueProp?: string;
  trackProp?: string;
  valueAsObject?: boolean;
  searchable?: boolean;
  options: OptionType[] | OptionFunction;
}>(), {
  modelValue: '',
  size: Styles.Sizes.Medium,
  label: '',
  placeholder: '',
  tooltip: '',
  message: '',
  error: false,
  clean: false,
  disabled: false,
  readonly: false,
  max: -1,
  multiple: false,
  labelProp: 'label',
  valueProp: 'id',
  trackProp: 'label',
  valueAsObject: false,
  searchable: false,
  options: () => []
});

const { show, placement, openStatus } = useDropdown();

const input = ref<HTMLElement>();
const reference = computed(() => input.value?.container.border);

const { width } = useElementSize(input);

const emit = defineEmits(['clear', 'input', 'input:value', 'select', 'deselect', 'search', 'paste']);

const {
  iv,
  setInternal,
  makeExternal,
  makeExternalOne,
  clear,
  hasSelected,
  isMax,
  valueText,
  labelText,
  getValue,
  update,
} = useValue(props, emit);

const {
  search,
  clearSearch,
  searchFilter,
  // innerInput,
  focusInput,
} = useSearch(props, emit);

const {
  eo,
  filteredOptions,
  loading,
  // selectAll,
  isSelected,
  handleOptionClick,
} = useOptions(props, emit, { iv, update, clear, getValue, isMax, search, makeExternal, makeExternalOne, searchFilter, clearSearch, setInternal, show });

// const noOptions = computed(() => !eo.value.length && !loading.value && !search.value);
// const noResults = computed(() => filteredOptions.value.length === 0 && search.value);

const chevron = ref();
const ignore = [chevron, reference];
</script>

<template lang="pug">
Input(
  ref="input"
  v-model="search"
  :size="size"
  :label="label"
  :clean="clean"
  :tooltip="tooltip"
  :readonly="readonly"
  :disabled="disabled"
  :placeholder="placeholder"
  :error="error"
  :message="message"
  :background="background"
  :open="openStatus"
  @focus="show = true"
  @clear="clear"
)
  template(#input v-if="!searchable")
    .w-100p.text-ellipsis
      template(v-if="hasSelected") {{ $t(labelText) }}
      template(v-else) {{ $t(placeholder) }}
  template(#right)
    Chevron.s-24(ref="chevron" :show="show" :disabled="readonly || disabled" @click.stop="show = !show")
  template(#additional)
    Dropdown(v-model:show="show" v-model:placement="placement" :reference="reference" :style="{width: `${width}px`}" :ignore="ignore")
      List
        ListItem(
          v-for="option of filteredOptions"
          :key="option[valueProp]"
          :modelValue="multiple ? iv.map(v => v[valueProp]) : iv[valueProp]"
          :multiple="multiple"
          :value="option[valueProp]"
          :label="option[labelProp]"
          @click="handleOptionClick(option)"
        )
</template>

<style scoped lang="scss">
@import "@ui/styles/scss/input";

@include input-left-part;

.left-part .icon {
  --uno: w-21;
}
</style>