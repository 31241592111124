import { computed, ref } from 'vue';

export function normalizeString(str: string, strict = true) {
  return strict
    ? String(str).toLowerCase().trim()
    : String(str)
      .normalize('NFD')
      .replace(/\p{Diacritic}/gu, '')
      .toLowerCase()
      .trim();
}

export type SearchFilter = (val: string) => boolean;

export default function useSearch(props: { searchable: boolean }, emit: ReturnType<typeof defineEmits>) {
  /** Search */
  const search = ref('');

  function clearSearch(immediate = true) {
    if (immediate) {
      search.value = '';
    } else {
      setTimeout(clearSearch, 1000);
    }
  }

  const normalizedSearch = computed(() => normalizeString(search.value));

  function searchFilter(val: string) {
    return normalizeString(val).includes(normalizedSearch.value);
  }

  /** Input */

  const innerInput = ref();

  function focusInput() {
    if (props.searchable && innerInput.value) {
      innerInput.value.focus();
    }
  }

  return {
    search,
    clearSearch,
    searchFilter,
    innerInput,
    focusInput,
  };
}
