import { computed, ref, toRefs, watch } from 'vue';
import type { Ref } from 'vue';
import { isObject } from '@vueuse/core';
import { isEqual } from 'radash';

export type OptionFunction = (filter: any) => Promise<[]> | any;

interface OptionsProps {
  options: OptionFunction;
  valueProp: string;
  trackProp: string;
  labelProp: string;
  limit: number;
  filter: any;
}

interface AsyncOptionsResolver {
  loading: Ref<boolean>;
  search: Ref<string>;
  getOptionItem: (val: string) => object;
  findOption: (val: string, arr: any[]) => object | undefined;
}

interface AsyncOptionsResolverReturn {
  ro: Ref<any[] | object>;
  searchOptionById: searchOptionFunction;
  useLoading: (f: () => any) => Promise<any>;
}

type searchOptionFunction = (id: string) => Promise<object>;

function useAsyncOptionsResolver(
  props: OptionsProps,
  { loading, search, getOptionItem, findOption }: AsyncOptionsResolver,
): AsyncOptionsResolverReturn {
  const ro: Ref<any[]> = ref([]);

  const resolver: Ref<any[] | Promise<any[]>> = ref<any[]>([]);

  function searchOptions(filters = {}) {
    return props.options({
      limit: 50,
      ...filters,
    });
  }

  async function useLoading(f: () => Promise<any>) {
    loading.value = true;
    return f().finally(() => {
      loading.value = false;
    });
  }

  async function resolveOptions() {
    resolver.value = useLoading(() =>
      searchOptions({
        ...props.filter,
        query: search.value,
      }),
    );
    ro.value = await resolver.value;
    // callback(ro.value);
  }

  async function searchOptionById(id: string) {
    const optInOptions = findOption(id, await resolver.value);
    if (optInOptions) return optInOptions;
    const opts = await searchOptions({ query: id });

    const opt = opts && findOption(id, opts);

    return opt || getOptionItem;
  }

  watch(search, resolveOptions);
  watch(
    () => props.filter,
    (newF, oldF) => {
      if (!isEqual(newF, oldF)) resolveOptions();
    },
  );

  setTimeout(resolveOptions);

  return { ro, searchOptionById, useLoading };
}

export function useOptionsResolver(
  props: OptionsProps,
  { search, searchFilter, getValue }: { search: Ref<string>; searchFilter: (o: any) => boolean; getValue: (o: any) => any },
) {
  const { options, valueProp, trackProp, labelProp } = toRefs(props);

  /*****************************************************************************
   * 1. Получение сырых опций
   ****************************************************************************/
  const loading = ref(false);
  const optionsIsFunction = typeof options.value === 'function';
  const { ro, searchOptionById, useLoading } = optionsIsFunction
    ? useAsyncOptionsResolver(props, { loading, search, getOptionItem, findOption })
    : { ro: options, searchOptionById: findOrCreateOption, useLoading: (f: () => any) => f() };

  /*****************************************************************************
   * 2. Приведение опций к единому виду
   ****************************************************************************/

  /**
   * Выделение опций из примитивов
   */
  function getOptionFromPrimitive(val: any) {
    return {
      [valueProp.value]: val,
      [trackProp.value]: val,
      [labelProp.value]: val,
    };
  }

  function getOptionItem(val: any) {
    return typeof val === 'object' ? val : getOptionFromPrimitive(val);
  }

  /**
   * Выделение опций из объекта
   */
  function getOptionsFromObject(options: object) {
    return Object.entries(options).map(([key, val]) => {
      const keyNum = Number(key);
      const keyVal = !isNaN(keyNum) ? keyNum : key;

      return {
        [valueProp.value]: keyVal,
        [trackProp.value]: val,
        [labelProp.value]: val,
      };
    });
  }

  /**
   * Выделение опций из массивов и объектов
   */
  function extractOptions(options: [] | object) {
    if (Array.isArray(options)) {
      return options.map(getOptionItem);
    }

    if (isObject(options)) {
      return getOptionsFromObject(options);
    }

    return [];
  }

  const eo: Ref<any[]> = computed(() => extractOptions(ro.value || []));

  /*****************************************************************************
   * 3. Фильтрация опций
   ****************************************************************************/

  function findOption(val: any, values = eo.value) {
    return values.find((v) => String(getValue(v)) === String(val));
  }

  function findOrCreateOption(val: any) {
    return findOption(val) || getOptionItem(val);
  }

  /**
   * Фильтрация только в случае фиксированного списка
   */
  function getFilteredOptions() {
    if (optionsIsFunction) return eo;

    return computed(() => {
      let options = eo.value;

      if (search.value) {
        options = options.filter((option: any) => searchFilter(option[trackProp.value]));
      }

      return options;
    });
  }

  const filteredOptions = getFilteredOptions();

  return {
    eo,
    loading,
    filteredOptions,
    useLoading,
    findOption,
    searchOptionById,
  };
}
